import '../styles/pages.scss'

import $ from 'jquery'
import 'select2/dist/js/select2.full.min'
import '@fancyapps/fancybox/dist/jquery.fancybox.min'

$(document).ready(() => {
  setProductIds()

  $('.select2bs4').select2({
    theme: 'bootstrap4',
    width: 'resolve'
  }).on('select2:select', (select2Event) => {
    const event = new Event('change', {
      bubbles: true
    }) // fire a native event
    setProductIds()
    select2Event.currentTarget.dispatchEvent(event)
  })
})

document.addEventListener('stimulus-reflex:success', (event) => {
  setProductIds()
  $('.select2bs4').select2({
    theme: 'bootstrap4',
    width: 'resolve'
  })
})

function setProductIds() {
  const selectedValues = Array.from(document.querySelectorAll('.selected-device')).map((el) => el.value)
  const dataProductIds = selectedValues.join(',')
  document.querySelectorAll('.selected-device').forEach((el) => {
    el.setAttribute('data-product-ids', dataProductIds)
  })
}
